import { hasKey } from 'holded/lib/utils';
import { CardBackground, CardSection } from 'holded/modules/cms/domain/components/card';
import { CardListTheme } from 'holded/modules/cms/ui/sections/cardList/cardListTheme';
import NextImage from 'holded/modules/cms/ui/shared/components/Image';

type Props = {
  data: CardSection;
  background: CardBackground;
};

const CARD_LIST_THEME = CardListTheme.cardList;

const Card = ({ data: { id, href, image, newTab, title, description }, background }: Props) => {
  const bg = hasKey(CARD_LIST_THEME.titleColor, background) && CARD_LIST_THEME.titleColor[background];
  const titleColor = hasKey(CARD_LIST_THEME.titleColor, background) && CARD_LIST_THEME.titleColor[background];
  const descriptionColor =
    hasKey(CARD_LIST_THEME.descriptionColor, background) && CARD_LIST_THEME.descriptionColor[background];
  return (
    <div key={id}>
      <a
        href={href ?? ''}
        className="space-y-4 cursor-pointer group"
        target={newTab ? '_blank' : '_self'}
        rel="noreferrer"
      >
        <div
          className={`${bg} ${
            titleColor === 'text-white' ? 'hover:text-blue-300' : 'hover:text-blue'
          } group-hover:opacity-60`}
        >
          <div className="relative aspect-w-3 aspect-h-2">
            {image?.data && <NextImage media={image.data} classImg={`object-cover !h-full rounded-lg`} />}
          </div>
          <div className={`space-y-2`}>
            <div className="font-normal space-y-1">
              <h3 className={`font-bold mt-6 text-b1 leading-8`}>{title}</h3>
              <span className={`text-sm ${descriptionColor} text-b3 leading-6`}>{description}</span>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};

export default Card;
